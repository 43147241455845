.nav-base {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: max-content;
    width: 420px;
    box-sizing: border-box;
    padding: 20px;
    gap: 3px;
}

.nav-link-drop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px 10px;
}

.nav-link-drop:hover {
    background-color: #E6E6E6;
}

.nav-link{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
    gap:10px;
    padding: 0px 10px;
    cursor: pointer;
    text-decoration: none;
  
}

.nav-link:hover {
    background-color: #E6E6E6;
}

.drop-pad{
    padding-left: 15px;
    box-sizing: border-box;
    width: 100%;
    gap:3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.new-run{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
    height: 56px;
    width: 200px;
    background-color: #17823B;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
}