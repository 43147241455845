.todo-base{
    display: flex;
    flex-direction: column;
   background-color: #fff;
   border-radius: 20px;
   width: 100%;
   box-sizing: border-box;
   padding: 20px;
   gap:20px;
   height: max-content;
}

.tasks{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 25%;
    cursor: pointer;
}

.todo-card {
    border-radius: 20px;
    height: max-content;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid #C2D6C9
}

.todo-card-row-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
  background-color: #F5F5F5;
    height: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.todo-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    border-top: 1px solid #C2D6C9;
    height: 60px;
}