.pd-base {
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    gap: 20px;
    height: max-content;
}


.pd-yield-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    height: 100px;
    gap: 20px;
    width: 100%;
}

.pd-detail-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    /* height: 100px; */
    gap: 20px;
    width: 100%;
    height: max-content;
}

.pd-estimate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    height: 50px;
    /* border-bottom: 2px solid #C2D6C9; */
    cursor: pointer;
}

.pd-number-back {
    background-color: #E6E6E6;
    height: 20px;
    width: 32px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
}

.pd-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
    height: max-content;
}

.pd-notes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    height: max-content;
    gap: 20px;
    width: 100%;
}

.pd-notes-input {
    border: 1px solid #669977;
    width: 100%;
    /* height: 300px; */
}