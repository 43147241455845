.plants-card {
    border-radius: 20px;
    height: max-content;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.width-check {
    width: 30px;
}

.width-id {
    width: 50px;
}

.width-type {
    width: 150px;
}

.width-summary {
    width: 40%
}

.plant-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    border-bottom: 1px solid #C2D6C9;
    height: 60px;
}

.plants-pages{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 5px;
}