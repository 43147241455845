.row-sc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-sbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.row-sbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-sbe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.row-ec {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-ss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-ss {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-cs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.gap5 {
  gap: 5px
}

.gap10 {
  gap: 10px
}

.gap15 {
  gap: 15px
}

.gap20 {
  gap: 20px
}


.pad-t20 {
  padding-top: 20px;
}

.pad-b20{
  padding-bottom: 20px;
}


.green29 {
  color: #293D30
}

.green3D {
  color: #3D5C47
}

.green85 {
  color: #85AD92
}

.greenC2 {
  color: #C2D6C9
}

.white {
  color: #fff;
}

.grey99 {
  color: #999999
}

.grey4D {
  color: #4D4D4D
}


.wrap{
  flex-wrap: wrap;
}



.pointer {
  cursor: pointer;
}

.width100p {
  width: 100%
}

.width50p {
  width: 50%
}

.height100p {
  height: 100%;
}

a, a:visited, a:hover, a:focus, a:active {
  color: inherit;        /* Ensures that links do not change color */
  text-decoration: none; /* Removes underline from links */
}