.header-base {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  



}