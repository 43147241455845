.new-seed-button {
    height: 40px;
    border-radius: 10px;
    border: 1px solid #17823B;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
    width: max-content;
    background-color: transparent;
    cursor: pointer;
}

.pages-end-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px ;
    width: 100%;
    box-sizing: border-box;
}

.add-seed-button {
    height: 56px;
    border-radius: 10px;
    border: 1px solid #17823B;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 40px;
    width: max-content;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
}

