.first-batch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 10px 30px;
    height: 100px;
    box-sizing: border-box;
}

.batches-overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    gap: 20px
}

.batches-overview-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    gap: 20px
}

.grey-icon-back {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px;
    background-color: #E6E6E6;
    border-radius: 5px;
    box-sizing: border-box;

}

.batch-details-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: max-content;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    gap: 30px;
    border-radius: 10px;
}

.batch-summary-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: max-content;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
}

.summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
}

.summary-head {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    box-sizing: border-box;
    width: 100%;
}

.border-lr {
    height: 100%;
    border-left: 1px solid #C2D6C9;
    border-right: 1px solid #C2D6C9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    width: 100px;
}

.no-border {

    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    width: 100px;

}

.sep-lightgreen {
    width: 100%;
    height: 1px;
    background-color: #C2D6C9;
}

.plant-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    height: max-content;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    gap: 20px
}

.new-plant-button {
    height: 40px;
    border-radius: 10px;
    background-color: #17823B;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: unset;
    padding: 0px 20px;
    width: max-content;
}

.plant-card-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    border: 1px solid #C2D6C9;
    border-radius: 10px;

}

.plant-card-half {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 50px;
    box-sizing: border-box;
    padding-left: 10px;
}

.plant-card-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 5px;
}

.rows-outline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: 1px solid #669977;
    box-sizing: border-box;
    padding: 0px 10px;
    gap: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.phase-progress {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 13%;
    height: max-content;
    gap: 20px
}

.progress-icon-done {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #B3B3B3;
}

.progress-icon-undone {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #E6E6E6;
}


.progress-line-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px
}

.progress-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
    padding: 5px 0px 5px 10px
}

.progress-line {
    height: 100px;
    width: 4px;
    background-color: #B3B3B3;
}

.progress-line-undone {
    height: 100px;
    width: 4px;
    background-color: #E6E6E6;
}