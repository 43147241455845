.strain-modal-base {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    padding-top: 5%;
    /* background-color: antiquewhite; */
    outline: 0;

}

.strain-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    box-sizing: border-box;
    min-height: 500px;
    background-color: rgb(233, 233, 233);
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    gap: 20px
}

.strain-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    background-color:#fff;
}

.strain-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    min-height: 200px;
    gap: 20px;
    width: 100%;
    height: max-content;

}

.edit-strain-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    box-sizing: border-box;
    min-height: 500px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    gap: 20px;
    height: 600px;
  
}

.edit-strain-scroll{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    height: max-content;
    gap:20px


}
