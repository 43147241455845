.devices-filter{
    border-radius: 5px;
    background-color:rgb(215, 215, 215);
    height: 72px;
    width: 430px;
    box-sizing: border-box;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}